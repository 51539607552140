<template>
    <div>
        <a-row type="flex" justify="space-between" align="middle" class="bottom-line">
            <a-col :span="4">

                <span v-if="feedback.state=='01'">
                                <a-tag color="red">
                                       {{feedback.stateName}}
                                </a-tag>
                            </span>
                <span v-else-if="feedback.state=='02'">
                                <a-tag color="orange">
                                       {{feedback.stateName}}
                                </a-tag>
                            </span>
                <span v-else>
                                <a-tag color="blue">
                                       {{feedback.stateName}}
                                </a-tag>
                 </span>
            </a-col>
            <a-col :span="4" class="header-text">
                {{feedback.tenantName}}
            </a-col>
            <a-col :span="4">
                                <span v-if="feedback.feedbackTypeCode=='problem'">
                                                <a-tag color="#108ee9">
                                                       {{feedback.feedbackTypeName}}
                                                </a-tag>
                                            </span>
                <span v-else>
                                                <a-tag color="#87d068" v-if="feedback.feedbackTypeName">
                                                       {{feedback.feedbackTypeName}}
                                                </a-tag>
                                       </span>
            </a-col>
        </a-row>

        <div class="scroll-container">
            <div class="bottom-line" v-for="(item,key) in feedbackList">
                <a-row>
                    <a-col :span="8" class="data-header-text">
                        {{item.realName+'('+item.userName+')'}}
                    </a-col>
                    <a-col :span="11">

                    </a-col>
                    <a-col :span="5" class="data-header-time">
                        {{item.creationTime.format('YYYY-MM-DD HH:mm:ss')}}
                    </a-col>
                </a-row>
                <a-row class="data-content">
                    <a-col :span="24">
                        <p class="content">
                            {{item.feedbackContent}}
                        </p>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="20">
                        <a-upload
                                list-type="picture-card"
                                :file-list="getImg(item.feedbackPicture,item.tenantId)"
                                @preview="handlePreview"
                                :show-upload-list="{
                                 showRemoveIcon: false
                            }"
                        >
                            <div v-if="false">
                                <a-icon type="plus"/>
                                <div class="ant-upload-text">
                                    Upload
                                </div>
                            </div>
                        </a-upload>
                        <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible=false">
                            <img alt="example" style="width: 100%" :src="previewImage"/>
                        </a-modal>
                    </a-col>
                </a-row>

                <a-row class="data-detail">
                    <a-col :span="24">
                        <p>
                            {{formatString(item.phoneBrand)+'&nbsp;'+formatString(item.systemName)+'&nbsp;'+formatString(item.appEdition)}}
                        </p>
                    </a-col>
                </a-row>
            </div>
        </div>
        <a-row style="margin-top: 5px;line-height: 35px" v-if="isshowProblemType">
            <a-col :span="4">
                意见分类:
            </a-col>
            <a-col :span="20">
                <a-select style="width: 100%"
                          allowClear
                          v-model="problemType"
                          placeholder="意见分类">
                    <a-select-option v-for="item in problemTypeList" :key="item.itemDetailCode">
                        {{item.itemDetailName}}
                    </a-select-option>
                </a-select>
            </a-col>
        </a-row>
        <div v-if="this.feedback.state!='03'">
            <a-row class="data-bottom">
                <a-col :span="24">
                    <a-textarea :rows="5" v-model="feedbackEditDto.feedbackContent" placeholder="请输入不少于5个字的反馈内容"/>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="24" class="upload-img">
                    <a-upload
                            name="profilePictureFile"
                            :action="uploadPictureUrl"
                            listType="picture-card"
                            :fileList="fileList"
                            :beforeUpload="beforeUpload"
                            accept="image/*"
                            :headers="uploadHeaders"
                            @preview="handlePreview"
                            @change="uploadPictureChange($event)">
                        <div v-if="fileList.length < 5">
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">上传图片</div>
                        </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null"
                             @cancel="previewVisible=false">
                        <img alt="example" style="width: 100%" :src="previewImage"/>
                    </a-modal>
                </a-col>
            </a-row>
            <!-- 按钮 -->
            <div class="bottom-btn">
                <a-button :loading="saving" :type="'primary'" @click="save()">
                    <a-icon type="save"/>
                    {{ '保存'}}
                </a-button>
            </div>
        </div>
        <div v-else>
            <a-row type="flex" align="bottom">
                <a-col :span="24">
                    <a-rate v-model="feedback.evaluationGrade" disabled style="margin-right: 10px;"/>
                    问题是否解决：{{feedback.problemEvaluation ? '已解决' : '未解决'}}
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {FeedbackEditDto, FeedbackListDto, FeedbackServiceProxy} from "../../../../shared/service-proxies";
    import ImageUtils from "../../../../shared/utils/image-utils";
    import {AppConsts} from "../../../../abpPro/AppConsts";
    import $ from "jquery"
    import {Dic} from "../../../../shared/utils";

    export default {
        name: "reply-feeback",
        mixins: [ModalComponentBase],
        data() {
            return {
                feedback: new FeedbackListDto(),
                feedbackList: [],
                moment,
                previewVisible: false,
                previewImage: undefined,
                imgPath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/Feedback/`,
                // 上传的接口地址
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadFilePicture",
                maxAppPictureBytesValue: AppConsts.maxProfilPictureMb,
                fileList: [],
                feedbackPicture: "",
                uploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken(),
                    type: "feedback"
                },
                feedbackEditDto: new FeedbackEditDto(),
                problemTypeList: [],
                problemType:undefined
            };
        },
        created() {
            this.fullData();
            this._feedbackServiceProxy = new FeedbackServiceProxy(this.$apiUrl, this.$api);
        },
        computed: {
            isshowProblemType() {
                return abp.session.multiTenancySide === 'Host'&&this.feedback.state!='03';
            }
        },
        async mounted() {
            try {
                this.loading = true;
                this.problemTypeList = await Dic.getInstance().getDicAsync("QYB_ProblemType");
            } catch (e) {
                console.log(e)
            } finally {
                this.loading = false;
            }

            this.getData();

        },
        methods: {
            getData() {
                this.loading = true;
                this._feedbackServiceProxy.getFeedbackDetail(this.feedback.id)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(res => {
                        this.feedbackList = res;
                        this.problemType=this.feedbackList.length>0?this.feedbackList[0].problemType:undefined;
                        this.$nextTick(() => {
                            setTimeout(() => {
                                $('.scroll-container').scrollTop(9999999999999)
                            }, 500)
                        })
                    });

            },
            getImg(strImg, tenantId) {
                let img = strImg.split(',');
                let imgList = [];
                img.forEach((item, index) => {
                    if (item) {
                        imgList = [...imgList, {
                            uid: index,
                            name: item,
                            status: 'done',
                            url: this.imgPath + tenantId + "/" + item,
                        }]
                    }
                });
                return imgList;
            },
            async handlePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await ImageUtils.getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            /**
             * 上传图片
             */
            beforeUpload(file) {
                const isJPG =
                    file.type === "image/jpeg" ||
                    file.type === "image/png" ||
                    file.type === "image/gif";
                if (!isJPG) {
                    abp.message.error("只能选择1M以内的Jpeg、Png、Gif图片文件");
                }
                const isLtXM = file.size / 1024 / 1024 < this.maxAppPictureBytesValue;
                if (!isLtXM) {
                    abp.message.error(
                        "只能选择1M以内的Jpeg、Png、Gif图片文件"
                    );
                }
                return isJPG && isLtXM;
            },
            /**
             * 上传图片回调  状态 "status: "done"  status: "uploading"  status: "removed"
             */
            uploadPictureChange({file, fileList}) {
                if (file.status === 'done') {
                    this.feedbackPicture += file.response.result.fileName + ",";
                } else if (file.status === 'removed') {
                    let s = this.feedbackPicture ? this.feedbackPicture.split(',').filter(item => item != file.response.result.fileName) : [];
                    this.feedbackPicture = s.join(',');
                }
                console.log(this.feedbackPicture);
                console.log(fileList);
                this.fileList = fileList;
            },
            save() {
                // if (!this.feedbackEditDto.feedbackContent) {
                //     abp.message.error("请输入回复文字")
                //     return;
                // }
                this.feedbackEditDto.feedbackPicture = this.feedbackPicture;
                this.feedbackEditDto.userId = abp.session.userId;
                this.feedbackEditDto.identification = 1;
                this.feedbackEditDto.parentId = this.feedback.id;
                this.feedbackEditDto.problemType=this.problemType;
                this.loading = true;
                this._feedbackServiceProxy.add(this.feedbackEditDto).finally(() => {
                    this.loading = false;
                }).then(res => {
                    this.$notification["success"]({
                        message: "保存成功"
                    });
                    this.success(true);
                });
            },
            formatString(str) {
                if (!str) {
                    return '';
                } else {
                    return str;
                }
            }
        },
    };
</script>

<style scoped lang="less">
    .header-text {
        font-size: 18px;
    }

    .bottom-line {
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #e8e8e8;
    }

    .data-header-text {
        font-size: 14px;
    }

    .data-header-time {
        font-size: 10px;
    }

    .data-content {
        padding-top: 8px;
        font-size: 13px;
    }

    .content {
        text-indent: 2em;
    }

    .data-detail {
        font-size: 10px;
    }

    .data-bottom {
        padding-top: 10px;
    }

    .scroll-container {
        min-height: 300px;
        max-height: 300px;
        overflow-y: scroll;
    }

    .upload-img {
        margin-top: 10px;
    }

    .bottom-btn {
        text-align: right;
        /*display: flex;*/
        /*justify-content: flex-end;*/
    }

    /*滚动条样式*/
    .scroll-container::-webkit-scrollbar {
        width: 4px;
    }

    .scroll-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
    }

     .scroll-container::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }

</style>
