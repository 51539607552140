<template>
	<a-config-provider>
		<a-spin :spinning="false">
			<div class="modal-header">
				<div class="modal-title">
					<span>{{ l('查看详情') }}</span>
				</div>
			</div>
			<div class="my-md">
				<a-row>
					<a-col :span="4">
						<q-label :label="l('提交人')"> </q-label>
					</a-col>
					<a-col :span="8">
						{{ feedback.userName }}
					</a-col>
					<a-col :span="4">
						<q-label :label="l('租户')"> </q-label>
					</a-col>
					<a-col :span="8">
						{{ feedback.tenantName }}
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('设备系统')"> </q-label>
					</a-col>
					<a-col :span="8">
						{{ feedback.systemName }}
					</a-col>
					<a-col :span="4">
						<q-label :label="l('版本')"> </q-label>
					</a-col>
					<a-col :span="8">
						{{ feedback.appEdition }}
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="4">
						<q-label :label="l('反馈时间')"> </q-label>
					</a-col>
					<a-col :span="8">
						{{ feedback.creationTime }}
					</a-col>
					<a-col :span="4">
						<q-label :label="l('反馈分类')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span v-if="feedback.feedbackTypeCode == 'problem'">
							<a-tag color="red">
								{{ feedback.feedbackTypeName }}
							</a-tag>
						</span>
						<span v-else>
							<a-tag color="blue" v-if="feedback.feedbackTypeName">
								{{ feedback.feedbackTypeName }}
							</a-tag>
						</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('设备信息')"> </q-label>
					</a-col>
					<a-col :span="20">
						{{ feedback.phoneBrand }}
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('图片')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-upload list-type="picture-card" :file-list="fileList" @preview="handlePreview">
							<div v-if="false">
								<a-icon type="plus" />
								<div class="ant-upload-text">
									Upload
								</div>
							</div>
						</a-upload>
						<a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
							<img alt="example" style="width: 100%" :src="previewImage" />
						</a-modal>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('内容')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-textarea class="tarea" disabled :rows="5" v-model="feedback.feedbackContent" />
					</a-col>
				</a-row>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import { FeedbackListDto } from '../../../../shared/service-proxies';
import { AppConsts } from '../../../../abpPro/AppConsts';
import ImageUtils from '../../../../shared/utils/image-utils';
import { abpService } from '../../../../shared/abp';
import { Dic } from '../../../../shared/utils';

export default {
	name: 'host-feedback-view',
	mixins: [ModalComponentBase],
	data() {
		return {
			feedback: new FeedbackListDto(),
			imgPath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/Feedback/`,
			fileList: [],
			previewVisible: false,
			previewImage: undefined,
		};
	},
	created() {
		this.fullData();
	},
	mounted() {
		if (this.feedback.feedbackPicture) {
			this.getImg();
		}
	},
	methods: {
		getImg() {
			let img = this.feedback.feedbackPicture.split(',');
			img.forEach((item, index) => {
				if (item) {
					this.fileList = [
						...this.fileList,
						{
							uid: index,
							name: item,
							status: 'done',
							url: this.imgPath + this.feedback.tenantId + '/' + item,
						},
					];
				}
			});
		},
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await ImageUtils.getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
	},
};
</script>

<style scoped lang="less">
.tarea {
	background-color: white;
	cursor: auto;
	color: #333333;
}
/deep/ .anticon-delete {
	display: none;
}
</style>
