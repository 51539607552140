<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8">
				<a-col :span="5">
					<a-button :type="'danger'" v-if="isHost" @click="BatchDelete()">
						<a-icon type="delete" />
						<span>{{ l('Delete') }}</span>
					</a-button>
					<a-button :type="'primary'" v-if="isGranted('feedback_clock')" @click="Close()">
						<a-icon type="primary" />
						<span>关闭反馈</span>
					</a-button>
				</a-col>
				<a-col :span="19">
					<a-row :gutter="12" type="flex" justify="end">
						<a-col :span="!isHost ? 5 : 0" style="text-align: right;line-height: 35px">
							<a-checkbox v-model="isMe" @change="getData" v-if="isTenant && isGranted('feedback_isme')">
								只看我提交的
							</a-checkbox>
						</a-col>
						<a-col :span="4" v-if="isHost">
							<a-select
								style="width: 100%"
								@change="getData"
								allowClear
								v-model="tenantId"
								placeholder="租户"
							>
								<a-select-option v-for="item in tenantList" :key="item.id">
									{{ item.name }}
								</a-select-option>
							</a-select>
						</a-col>
						<a-col :span="4">
							<a-select
								style="width: 100%"
								@change="getData"
								allowClear
								v-model="feedbackState"
								placeholder="反馈状态"
							>
								<a-select-option v-for="item in feedbackStateList" :key="item.code">
									{{ item.name }}
								</a-select-option>
							</a-select>
						</a-col>
						<a-col :span="4">
							<a-select
								style="width: 100%"
								@change="getData"
								allowClear
								v-model="feedbackType"
								placeholder="反馈分类"
							>
								<a-select-option v-for="item in feedbackTypeList" :key="item.itemDetailCode">
									{{ item.itemDetailName }}
								</a-select-option>
							</a-select>
						</a-col>
						<a-col v-if="isHost" :span="4">
							<a-select
								style="width: 100%"
								allowClear
								v-model="problemType"
								@change="getData"
								placeholder="意见分类"
							>
								<a-select-option v-for="item in problemTypeList" :key="item.itemDetailCode">
									{{ item.itemDetailName }}
								</a-select-option>
							</a-select>
						</a-col>
						<!-- 搜索 -->
						<a-col :span="7">
							<a-input-search
								name="filterText"
								:placeholder="l('SearchWithThreeDot')"
								@search="search"
								enterButton
								v-model="filterText"
							/>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: 1600, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<span slot="feedbackType" slot-scope="text, record">
					<span v-if="record.feedbackTypeCode == 'problem'">
						<a-tag color="#108ee9">
							{{ record.feedbackTypeName }}
						</a-tag>
					</span>
					<span v-else>
						<a-tag color="#87d068" v-if="record.feedbackTypeName">
							{{ record.feedbackTypeName }}
						</a-tag>
					</span>
				</span>
				<span slot="state" slot-scope="text, record">
					<span v-if="record.state == '01'">
						<a-tag color="red">
							{{ record.stateName }}
						</a-tag>
					</span>
					<span v-else-if="record.state == '02'">
						<a-tag color="orange">
							{{ record.stateName }}
						</a-tag>
					</span>
					<span v-else>
						<a-tag color="blue">
							{{ record.stateName }}
						</a-tag>
					</span>
				</span>
				<span slot="actions" slot-scope="text, record">
					<a class="table-edit" @click="view(record)" v-if="isshowview(record) && record.state != '03'">
						{{ l('回复') }}</a
					>

					<a class="table-edit" @click="lookView(record)" v-if="record.state == '03'"> {{ l('查看') }}</a>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import * as _ from 'lodash';
import { FeedbackServiceProxy, TenantServiceProxy } from '../../../shared/service-proxies';
import moment from 'moment';
import HostFeedbackView from './host-feedback-view/host-feedback-view';
import { ModalHelper } from '../../../shared/helpers';
import ReplyFeedback from './reply-feeback/reply-feeback';
import { Dic } from '../../../shared/utils';

export default {
	name: 'host-feedback',
	mixins: [AppComponentBase],
	components: { HostFeedbackView, ReplyFeedback },
	data() {
		return {
			isAll: false,

			// 用户表格
			columns: [],
			// 用户数据
			tableData: [],
			feedbackTypeList: [
				{
					itemDetailCode: 'problem',
					itemDetailName: '遇到问题',
				},
				{
					itemDetailCode: 'suggest',
					itemDetailName: '使用建议',
				},
			],
			feedbackStateList: [
				{
					code: '01',
					name: '处理中',
				},
				{
					code: '02',
					name: '已回复',
				},
				{
					code: '03',
					name: '已关闭',
				},
			],
			feedbackType: undefined,
			feedbackState: undefined,
			isMe: false,
			problemTypeList: [],
			problemType: undefined,
			tenantId: undefined,
			tenantList: [],

			//多选
			isMultiple: true,
		};
	},
	created() {
		this._feedbackServiceProxy = new FeedbackServiceProxy(this.$apiUrl, this.$api);
		this._tenantService = new TenantServiceProxy(this.$apiUrl, this.$api);
		let columns1 = [
			{
				title: this.l('状态'),
				dataIndex: 'state',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'state' },
			},
			{
				title: this.l('账号'),
				dataIndex: 'userName',
				align: 'center',
				scopedSlots: { customRender: 'userName' },
			},
			{
				title: this.l('姓名'),
				dataIndex: 'realName',
				align: 'center',
				scopedSlots: { customRender: 'realName' },
			},
			{
				title: this.l('租户'),
				dataIndex: 'tenantName',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'tenantName' },
			},
			{
				title: this.l('反馈分类'),
				dataIndex: 'feedbackType',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'feedbackType' },
			},
			{
				title: this.l('反馈内容'),
				dataIndex: 'feedbackContent',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'feedbackContent' },
			},
			{
				title: this.l('设备系统'),
				dataIndex: 'systemName',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'systemName' },
			},

			{
				title: this.l('版本'),
				dataIndex: 'appEdition',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'appEdition' },
			},
			{
				title: this.l('反馈时间'),
				dataIndex: 'creationTime',
				ellipsis: true,
				align: 'center',
				width: 200,
				scopedSlots: { customRender: 'creationTime' },
			},
			{
				title: this.l('回复时间'),
				dataIndex: 'lastModificationTime',
				ellipsis: true,
				align: 'center',
				width: 200,
				scopedSlots: { customRender: 'lastModificationTime' },
			},
			{
				title: this.l('Actions'),
				dataIndex: 'actions',
				align: 'center',
				fixed: 'right',
				scopedSlots: { customRender: 'actions' },
				width: 100,
			},
		];
		let columns2 = [
			{
				title: this.l('状态'),
				dataIndex: 'state',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'state' },
			},
			{
				title: this.l('账号'),
				dataIndex: 'userName',
				align: 'center',
				scopedSlots: { customRender: 'userName' },
			},
			{
				title: this.l('姓名'),
				dataIndex: 'realName',
				align: 'center',
				scopedSlots: { customRender: 'realName' },
			},
			{
				title: this.l('租户'),
				dataIndex: 'tenantName',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'tenantName' },
			},
			{
				title: this.l('反馈分类'),
				dataIndex: 'feedbackType',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'feedbackType' },
			},
			{
				title: this.l('意见分类'),
				dataIndex: 'problemTypeName',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'problemTypeName' },
			},
			{
				title: this.l('反馈内容'),
				dataIndex: 'feedbackContent',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'feedbackContent' },
			},
			{
				title: this.l('设备系统'),
				dataIndex: 'systemName',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'systemName' },
			},

			{
				title: this.l('版本'),
				dataIndex: 'appEdition',
				ellipsis: true,
				align: 'center',
				scopedSlots: { customRender: 'appEdition' },
			},
			{
				title: this.l('反馈时间'),
				dataIndex: 'creationTime',
				ellipsis: true,
				align: 'center',
				width: 200,
				scopedSlots: { customRender: 'creationTime' },
			},
			{
				title: this.l('回复时间'),
				dataIndex: 'lastModificationTime',
				ellipsis: true,
				align: 'center',
				width: 200,
				scopedSlots: { customRender: 'lastModificationTime' },
			},
			{
				title: this.l('Actions'),
				dataIndex: 'actions',
				align: 'center',
				fixed: 'right',
				scopedSlots: { customRender: 'actions' },
				width: 100,
			},
		];
		if (abp.session.multiTenancySide === 'Tenant') {
			this.columns = [...columns1];
		} else {
			this.columns = [...columns2];
		}
	},
	computed: {
		isTenant() {
			return abp.session.multiTenancySide === 'Tenant';
		},
		isHost() {
			return abp.session.multiTenancySide === 'Host';
		},
	},
	async mounted() {
		try {
			this.loading = true;
			this.problemTypeList = await Dic.getInstance().getDicAsync('QYB_ProblemType');
			if (abp.session.multiTenancySide === 'Host') {
				this.tenantList = await this.getTenantList();
			}
		} catch (e) {
			console.log(e);
		} finally {
			this.loading = false;
		}
		this.getData();
		// this.feedbackTypeList=await Dic.getInstance().getDicAsync("QYB_FeedbackType");
	},
	methods: {
		isshowview(v) {
			return (
				abp.session.multiTenancySide === 'Host' ||
				v.userId == abp.session.userId ||
				abp.userInfo.isTenantAdmin == 1
			);
		},
		getTenantList() {
			return new Promise((resolve) => {
				this._tenantService.getTenantList().then((res) => {
					resolve(res);
				});
			});
		},
		getData() {
			this.loading = true;
			this._feedbackServiceProxy
				.getPage(
					this.feedbackType,
					this.feedbackState,
					this.isAll,
					this.isMe,
					this.problemType,
					this.tenantId,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
						item.creationTime = moment(item.creationTime).format('YYYY-MM-DD HH:mm');
						if (item.lastModificationTime) {
							item.lastModificationTime = moment(item.lastModificationTime).format('YYYY-MM-DD HH:mm');
						}
					});
					this.totalItems = res.totalCount;
				});
		},

		BatchDelete() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			this.$confirm({
				title: '确认操作',
				content: '你确认要删除这' + this.selectedRowKeys.length + '项',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					this.loading = true;
					this._feedbackServiceProxy
						.batchDelete(this.selectedRowKeys)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.search();
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
						});
				},
			});
		},
		Close() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			this.$confirm({
				title: '确认操作',
				content: '你确认要关闭这' + this.selectedRowKeys.length + '项',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					this.loading = true;
					this._feedbackServiceProxy
						.closeFeedback(this.selectedRowKeys)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.search();
							this.$notification['success']({
								message: '关闭成功',
							});
						});
				},
			});
		},
		view(record) {
			ModalHelper.create(
				ReplyFeedback,
				{
					feedback: record,
				},
				{
					width: '600px',
				}
			).subscribe((res) => {
				if (res) {
					this.search();
				}
			});
		},
		lookView(record) {
			ModalHelper.create(
				ReplyFeedback,
				{
					feedback: record,
				},
				{
					width: '600px',
				}
			).subscribe((res) => {
				if (res) {
					this.search();
				}
			});
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
